import { useState, useRef, useEffect } from 'react'
import CodeBlocks from './CodeBlocks'
import ChatInterface from './ChatInterface'
import SidebarPanel from '../../components/Sidebar/index'

export default function Artifacts() {
  const [codeBlocks, setCodeBlocks] = useState([])
  const [leftWidth, setLeftWidth] = useState(50) // 50% initial width
  const [isSidebarVisible, setIsSidebarVisible] = useState(false)
  const resizerRef = useRef(null)
  const leftPaneRef = useRef(null)
  const rightPaneRef = useRef(null)

  useEffect(() => {
    const resizer = resizerRef.current
    const leftPane = leftPaneRef.current
    const rightPane = rightPaneRef.current

    let x = 0
    let leftWidth = 0

    const mouseDownHandler = function (e) {
      x = e.clientX
      leftWidth = leftPane.getBoundingClientRect().width
      document.addEventListener('mousemove', mouseMoveHandler)
      document.addEventListener('mouseup', mouseUpHandler)
    }

    const mouseMoveHandler = function (e) {
      const dx = e.clientX - x
      const newLeftWidth = ((leftWidth + dx) / resizer.parentNode.getBoundingClientRect().width) * 100
      setLeftWidth(newLeftWidth)
    }

    const mouseUpHandler = function () {
      document.removeEventListener('mousemove', mouseMoveHandler)
      document.removeEventListener('mouseup', mouseUpHandler)
    }

    resizer.addEventListener('mousedown', mouseDownHandler)

    return () => {
      resizer.removeEventListener('mousedown', mouseDownHandler)
    }
  }, [])

  const handleSidebarVisibility = (isVisible) => {
    setIsSidebarVisible(isVisible)
  }

  return (
    <div className={`wrapper ${isSidebarVisible ? 'sidebar-visible' : ''}`}>
      <SidebarPanel onVisibilityChange={handleSidebarVisibility} />
      <div className="content-area">
        <div className="chat-pane" ref={leftPaneRef} style={{ width: `${leftWidth}%` }}>
          <ChatInterface setCodeBlocks={setCodeBlocks} />
        </div>
        <div className="resizer" ref={resizerRef}></div>
        <div className="code-pane" ref={rightPaneRef} style={{ width: `${100 - leftWidth}%` }}>
          <CodeBlocks codeBlocks={codeBlocks} onChange={setCodeBlocks} />
        </div>
      </div>
    </div>
  )
}