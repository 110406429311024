const exchangeRates = {
    USD: 1,
    EUR: 0.85,
    GBP: 0.73,
    INR: 81,
    JPY: 110,
    NZD: 1.39,
    CAD: 1.25,
    AUD: 1.49,
    THB: 32.5
};

// PayAsYouGo.js
const PayAsYouGo = ({ currencyInfo }) => {
    const convertPrice = (price) => {
        return (price * exchangeRates[currencyInfo.currency]).toFixed(2);
    };

    return (
        <section className="pay-as-you-go">
            <h2>Pay As You Go</h2>
            <p>Perfect for occasional users or those with varying needs</p>
            <div className="pay-as-you-go__pricing-details">
                <div className="pay-as-you-go__pricing-item">
                    <h3>Basic Usage</h3>
                    <p>{currencyInfo.symbol}{convertPrice(0.01)} per unit</p>
                </div>
                <div className="pay-as-you-go__pricing-item">
                    <h3>Advanced Features</h3>
                    <p>{currencyInfo.symbol}{convertPrice(0.05)} per unit</p>
                </div>
            </div>
            <button className="pay-as-you-go__cta-button">Get Started</button>
        </section>
    );
};

export default PayAsYouGo;