export default buffer => {
  const regex = /```(?:(html|css|js|javascript|jsx)?\s*)\n([\s\S]*?)(?:```|$)/g
  const matches = [...buffer.matchAll(regex)]
  return matches.map(match => {
    let language;
    if (match[1]) {
      language = match[1];
    } else {
      const code = match[2].trim();
      if (code.startsWith('<!')) {
        language = 'html';
      } else if (code.startsWith('import') || code.includes('import')) {
        language = 'jsx';
      } else if (code.startsWith('body') || code.startsWith('.') || code.startsWith('*') || code.startsWith('/*')) {
        language = 'css';
      } else {
        language = 'javascript';
      }
    }
    return {
      language,
      code: match[2].trim(),
      complete: match[0].endsWith('```')
    }
  })
}