import './index.css';

const GeometricShapesAnimation = ({ shapes, containerClassName }) => {
    return (
        <div className={`geometric-shapes-container ${containerClassName}`}>
            {shapes.map((shape, index) => (
                <div
                    key={index}
                    className={`geometric-shape ${shape.type}`}
                    style={{
                        backgroundColor: shape.color,
                        top: shape.top,
                        left: shape.left,
                        width: shape.size,
                        height: shape.size,
                        animationDuration: `${shape.duration}s`,
                        animationDelay: `${shape.delay}s`,
                    }}
                />
            ))}
        </div>
    );
};

export default GeometricShapesAnimation;