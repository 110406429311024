import "./index.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import TermsOfServiceSection from "./components/TermsOfServiceSection";
import TermsOfServiceItem from "./components/TermsOfServiceItem";

function TermsOfService() {
    return (
        <div className="terms">
            <Header />
            <div className="terms__content">
                <div className="terms__header">
                    <div className="terms__title">
                        <h2 className="terms__heading">Terms of Service</h2>
                        <p className="terms__description">
                            Welcome to bvmdotsh. We are excited to have you join us and be part of our platform and community.
                            Before you start using our platform, please take a moment to review our Terms and Conditions carefully.
                        </p>
                    </div>
                </div>

                <TermsOfServiceSection title="Introduction">
                    <TermsOfServiceItem text="These terms and conditions govern your use of our platform, and any services, products, or features provided through our platform. By accessing or using our platform, you agree to be bound by these terms and conditions." />
                    <TermsOfServiceItem text="These terms and conditions may be revised from time to time, and we will notify you of any changes. It is your responsibility to check our terms and conditions periodically to ensure that you are aware of any changes. If you continue using our platform after any changes, it will be deemed your acceptance of the new terms and conditions." />
                </TermsOfServiceSection>

                <TermsOfServiceSection title="User Obligations">
                    <TermsOfServiceItem text="You agree not to engage in any activity that interferes with the normal operation of our platform, including but not limited to hacking, spamming, phishing, or transmitting any malicious code or virus." />
                    <TermsOfServiceItem text="You agree not to use our platform to infringe on the intellectual property rights of others, including but not limited to copyrights, trademarks, patents, or trade secrets." />
                </TermsOfServiceSection>

                <TermsOfServiceSection title="Liability">
                    <TermsOfServiceItem text="We make no representations or warranties as to the accuracy or completeness of any information provided on our platform." />
                    <TermsOfServiceItem text="We shall not be liable for any direct or indirect loss or damage that you may suffer as a result of your use of our platform, including but not limited to any loss of profits, data, or business interruption." />
                    <TermsOfServiceItem text="We shall not be liable for any special, incidental, or consequential damages that may be incurred as a result of your use of our platform." />
                </TermsOfServiceSection>

                <TermsOfServiceSection title="Intellectual Property">
                    <TermsOfServiceItem text="All content on our platform, including but not limited to text, images, videos, and software, is the property of bvmdotsh and its licensors. You may not use our platform to upload, distribute, or otherwise make available any content that is not licensed under this policy." />
                    <TermsOfServiceItem text="You may not use our platform to transmit any content that infringes on the rights of others." />
                    <TermsOfServiceItem text="You agree not to reproduce, distribute, or use any of our intellectual property without our prior written consent." />
                </TermsOfServiceSection>

                <TermsOfServiceSection title="Miscellaneous">
                    <TermsOfServiceItem text="These terms and conditions constitute the entire agreement between you and us, and supersede all prior agreements and understandings, whether written or oral." />
                </TermsOfServiceSection>

                <TermsOfServiceSection title="Contact Us">
                    <TermsOfServiceItem text="If you have any questions about this Privacy Policy, please contact us at hello@bvm.sh / anshik1998@gmail.com." />
                </TermsOfServiceSection>
            </div>
            <Footer />
        </div>
    );
}

export default TermsOfService;