import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import { Eye, EyeOff, Users, Shield, Zap } from 'lucide-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faUsers, faShield, faBolt } from '@fortawesome/free-solid-svg-icons'
import Logo from './../../assets/images/bvm-sh-mascot-transparent-2x.png'
import { useUserData } from '../../store/userData';
import CONSTANTS from '../../config';
import './index.css';

const LoginSignup = () => {
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLogin, setIsLogin] = useState(true);
    const [showPassword, setShowPassword] = useState(false);

    const { setUserData } = useUserData();

    const toggleForm = () => setIsLogin(!isLogin);
    const togglePasswordVisibility = () => setShowPassword(!showPassword);

    async function handleSignUp(name, email, password, phoneNumber) {
        const taskBody = {
            name: name,
            userName: name.toLowerCase().replace(/\s+/g, ''),
            email: email,
            password: password,
            phoneNumber: phoneNumber
        };
        const bodyJSON = JSON.stringify(taskBody, null, 2);

        await fetch(`${CONSTANTS["BASE_API_URL"][CONSTANTS["ENVIRONMENT"]]}/api/v1/user/new`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: bodyJSON
        })
            .then((response) => {
                if (!response.ok) {
                    if (response.status === 400) {
                        setSuccessMessage(`You've already signed up. \nPlease login.`);
                    } else if (response.status) {
                        setErrorMessage(`Something went wrong. \nPlease attempt again later.`);
                    }
                }
                return response.json();
            })
            .then(async ({ success, data }) => {
                if (success) {
                    setSuccessMessage(`You have successfully signed up. \nPlease activate your account to login.`);
                }
            })
            .catch((error) => {
                setErrorMessage(`Something went wrong. \nPlease attempt again later.`);
            });
    }

    async function handleLogin(email, password) {
        console.log(email, password);
        const taskBody = { email: email, password: password };
        console.log("Task body: ", taskBody);
        const bodyJSON = JSON.stringify(taskBody);

        console.log(bodyJSON);

        await fetch(`${CONSTANTS["BASE_API_URL"][CONSTANTS["ENVIRONMENT"]]}/api/v1/user/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: bodyJSON
        })
            .then((response) => {
                console.log(response);
                if (!response.ok) {
                    if (response.status === 400) {
                        setSuccessMessage(`Please signup or activate your account.`);
                    } else if (response.status) {
                        setErrorMessage(`Something went wrong. \nPlease attempt again later.`);
                    }
                }
                return response.json();
            })
            .then(async ({ success, data }) => {
                console.log(data);
                if (success) {
                    setUserData({
                        jwtToken: data.jwtToken,
                        name: data.user.name,
                        userID: data.user._id,
                        apiKey: data.user.apiKey
                    });
                    setSuccessMessage(`You have successfully logged in.`, data);
                    window.location.href = '/';
                }
            })
            .catch((error) => {
                setErrorMessage(`Something went wrong. \nPlease attempt again later.`);
            });
    }

    return (
        <div className="auth">
            <div className="auth__left-section">
                <img src={Logo} alt="Company Logo" className="auth__logo" />
                {/* <h2 className="auth__tagline">Empowering Your Digital Journey</h2>
                <ul className="auth__benefits">
                    <li><Users size={20} /> Join over 1 million satisfied users</li>
                    <li><Shield size={20} /> Bank-level security for your data</li>
                    <li><Zap size={20} /> Lightning-fast performance</li>
                </ul> */}
            </div>
            <div className="auth__container">
                <img src={Logo} alt="Company Logo" className="auth__logo-mobile" />
                <h1 className="auth__title">{isLogin ? 'Welcome Back!' : 'Create Account'}</h1>
                <div className="auth__form">
                    {!isLogin && (
                        <div className="auth__form-group">
                            <label htmlFor="name" className="auth__label">Name</label>
                            <input type="text" id="name" className="auth__input" placeholder="Enter your name" onChange={(e) => setName(e.target.value)} />
                        </div>
                    )}
                    <div className="auth__form-group">
                        <label htmlFor="email" className="auth__label">Email</label>
                        <input type="email" id="email" className="auth__input" placeholder="Enter your email" onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    {!isLogin && (
                        <div className="auth__form-group">
                            <label htmlFor="phoneNumber" className="auth__label">Phone Number</label>
                            <input type="tel" id="phoneNumber" className="auth__input" placeholder="Enter your phone number" onChange={(e) => setPhoneNumber(e.target.value)} />
                        </div>
                    )}
                    <div className="auth__form-group">
                        <label htmlFor="password" className="auth__label">Password</label>
                        <div className="auth__password-input">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                className="auth__input"
                                placeholder="Enter your password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <button
                                type="button"
                                className="auth__password-toggle"
                                onClick={togglePasswordVisibility}
                            >
                                {showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                            </button>
                        </div>
                    </div>
                    {isLogin && (
                        <a href="#" className="auth__forgot-password">Forgot password?</a>
                    )}
                    <button
                        type="submit"
                        className="auth__submit-btn"
                        onClick={isLogin ?
                            () => handleLogin(email, password) :
                            () => handleSignUp(name, email, password, phoneNumber)
                        }
                    >
                        {isLogin ? 'Login' : 'Sign Up'}
                    </button>
                </div> {/*form*/}
                <p className="auth__terms">
                    By {isLogin ? 'logging in' : 'signing up'}, you agree to our{' '}
                    <Link className="auth__link" to="/terms-of-service">Terms of Service</Link> and {' '}
                    <Link className="auth__link" to="/privacy-policy">Privacy Policy</Link>.{' '}
                    {/* <a href="#" className="auth__link">Cookie Policy</a>. */}
                </p>
                <p className="auth__switch">
                    {isLogin ? "Don't have an account?" : "Already have an account?"}
                    <button className="auth__switch-btn" onClick={toggleForm}>
                        {isLogin ? 'Sign Up' : 'Login'}
                    </button>
                </p>
                {successMessage && <div className="auth__message auth__message--success">{successMessage}</div>}
                {errorMessage && <div className="auth__message auth__message--error">{errorMessage}</div>}
            </div>
        </div>
    );
};

export default LoginSignup;