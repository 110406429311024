import "./App.css";
import HeaderSection from "./components/Header";
import HeroSection from "./components/Hero";
import FooterSection from "./components/Footer";
import ApiPlaygroundSection from "./components/ApiPlayground";
import FaqSection from "./components/FAQ";

function App() {

  const faqs = [
    {
      question: 'What is bvmdotsh?',
      answer: 'bvmdotsh is a revolutionary platform that harnesses the power of workflows, APIs, and generative AI to automate any task, no coding required.',
    },
    {
      question: 'How does bvmdotsh work?',
      answer: 'bvmdotsh uses advanced AI and automation technologies to interact with websites just like a human would. It can click, type, press keys, and extract data, allowing you to automate complex tasks easily and efficiently.',
    },
    {
      question: 'What tasks can I automate with bvmdotsh?',
      answer: (
        <>
          <p>You can automate a variety of tasks, including:</p>
          <ul className="faq-section__answer-list">
            <li>Food delivery and ordering</li>
            <li>Sending emails and mail</li>
            <li>Booking cabs and flights</li>
            <li>Purchasing books and other products</li>
            <li>Summarizing YouTube videos</li>
            <li>Buying stocks after analyzing market trends</li>
          </ul>
          <p>The possibilities are virtually endless.</p>
        </>
      ),
    },
    {
      question: 'Can I customize the automation workflows?',
      answer: 'Yes, bvmdotsh allows you to customize automation workflows to suit your specific needs. You can create and modify workflows through an intuitive interface, making it easy to adapt bvmdotsh to your unique requirements.',
    },
    {
      question: 'Is my data secure with bvmdotsh?',
      answer: (
        <>
          <p>We prioritize your privacy and data security. </p>
          <p>bvmdotsh only stores the workflow steps and performance data, but we don't retain any results from workflow automations on our systems.</p>
          <p>For more details, please refer to our Privacy Policy.</p>
        </>
      ),
    },
  ];

  return (
    <div className="App">
      <HeaderSection />
      <HeroSection />
      <ApiPlaygroundSection />
      <FaqSection faqs={faqs} />
      <FooterSection />
    </div>
  );
}

export default App;
