import React from 'react';

const TermsOfServiceSection = ({ title, children }) => {
    return (
        <div className="terms__section">
            <h3 className="terms__subheading">{title}</h3>
            {children}
        </div>
    );
};

export default TermsOfServiceSection;
