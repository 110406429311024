import "./index.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import PrivacyPolicySection from "./components/PrivacyPolicySection";
import PrivacyPolicyItem from "./components/PrivacyPolicyItem";

function PrivacyPolicy() {
    return (
        <div className="privacy-policy">
            <Header />
            <div className="privacy-policy__content">
                <div className="privacy-policy__header">
                    <div className="privacy-policy__title">
                        <h2 className="privacy-policy__heading">Privacy Policy</h2>
                        <p className="privacy-policy__description">
                            This Privacy Policy informs you of our policies regarding the collection,
                            use, and disclosure of Personal Information we receive from users of the Services.
                        </p>
                        <p className="privacy-policy__description">
                            We use your Personal Information only for providing and improving our Services.
                            By using the Services, you agree to the collection and use of information in accordance with this policy.
                        </p>
                    </div>
                </div>

                <PrivacyPolicySection title="1. Cookies">
                    <PrivacyPolicyItem text="Cookies are files with a small amount of data that are commonly used as an anonymous unique identifier. These are sent to your browser from the website that you visit and are stored on your computer's hard drive." />
                    <PrivacyPolicyItem text="Like many sites, we use 'cookies' to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Services." />
                </PrivacyPolicySection>

                <PrivacyPolicySection title="2. Information Sharing">
                    <PrivacyPolicyItem text="We do not sell, trade, or rent your personal information to others. We may share your information with third-party service providers who assist us in operating our website and providing our services. These service providers are required to keep your information confidential and secure." />
                </PrivacyPolicySection>

                <PrivacyPolicySection title="3. Your Choices">
                    <PrivacyPolicyItem text="You can choose not to provide us with certain information, but this may limit the features of the website you are able to use. You can also opt-out of receiving promotional emails from us by following the instructions in those emails." />
                </PrivacyPolicySection>

                <PrivacyPolicySection title="4. Data Security">
                    <PrivacyPolicyItem text="We take reasonable measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the Internet or electronic storage is completely secure, so we cannot guarantee absolute security." />
                </PrivacyPolicySection>

                <PrivacyPolicySection title="5. Changes to This Policy">
                    <PrivacyPolicyItem text="We may update this Privacy Policy from time to time. If we make significant changes, we will notify you by email or through a notice on our website." />
                </PrivacyPolicySection>

                <PrivacyPolicySection title="6. Contact Us">
                    <PrivacyPolicyItem text="If you have any questions about this Privacy Policy, please contact us at hello@bvm.sh / anshik1998@gmail.com." />
                </PrivacyPolicySection>
            </div>
            <Footer />
        </div>
    );
}

export default PrivacyPolicy;
