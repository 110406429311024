import React from 'react';
import './index.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import bvmLogo from "./../../assets/images/bvm-mascot-transparent.png";

const FooterSection = () => {
    return (
        <footer className="footer">
            <div className="footer__content">
                <div className="footer__left">
                    <Link className='footer__link' to="/" style={{ textDecoration: "none" }}>
                        <img src={bvmLogo} alt="BVM Logo" className="footer__logo" />
                    </Link>
                    <div className="footer__logo-text">bvm.sh</div>
                    <div className="footer__logo-subheading">effortless automation, <br />endless possibilities.</div>
                </div>
                <div className="footer__right">
                    <div className="footer__section">
                        <h3 className="footer__section-heading">Pages</h3>
                        <ul className="footer__section-list">
                            <li><a href="/">Home</a></li>
                            <li><a href="/">About Us</a></li>
                            <li><a href="/">Contact</a></li>
                        </ul>
                    </div>
                    <div className="footer__section">
                        <h3 className="footer__section-heading">Legal</h3>
                        <ul className="footer__section-list">
                            <li><a href="/privacy-policy">Privacy Policy</a></li>
                            <li><a href="/terms-of-service">Terms of Service</a></li>
                        </ul>
                    </div>
                    <div className="footer__section">
                        <h3 className="footer__section-heading">Connect</h3>
                        <ul className="footer__section-list">
                            <li>
                                <a href="https://twitter.com/bvmdotsh">
                                    Twitter
                                    <FontAwesomeIcon
                                        icon={faArrowUpRightFromSquare}
                                        style={{ fontWeight: "300", fontSize: "1rem", paddingLeft: "5px" }}
                                    />
                                </a>
                            </li>
                            <li>
                                <a href="https://discord.gg/yDxcbQNTvV">
                                    Discord
                                    <FontAwesomeIcon
                                        icon={faArrowUpRightFromSquare}
                                        style={{ fontWeight: "300", fontSize: "1rem", paddingLeft: "5px" }}
                                    />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/bvmdotsh/">
                                    Instagram
                                    <FontAwesomeIcon
                                        icon={faArrowUpRightFromSquare}
                                        style={{ fontWeight: "300", fontSize: "1rem", paddingLeft: "5px" }}
                                    />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.facebook.com/bvmdotsh">
                                    Facebook
                                    <FontAwesomeIcon
                                        icon={faArrowUpRightFromSquare}
                                        style={{ fontWeight: "300", fontSize: "1rem", paddingLeft: "5px" }}
                                    />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="footer__line footer__line--bottom"></div>
            <div className="footer__copyright">
                <p>Copyright &copy; 2024. All Rights Reserved.</p>
                <p>Developed in <span role="img" aria-label="India">🇮🇳</span> with <span role="img" aria-label="Heart">❤️</span></p>
            </div>
        </footer>
    );
}

export default FooterSection;
