import './index.css';
import { useParams } from 'react-router-dom';
import { io } from 'socket.io-client';
import React, { useCallback, useEffect, useRef, useState } from "react";
import TaskCompletionImage from "./../../assets/images/task-completion.png"
import CONSTANTS from '../../config';

const socket = io.connect(`${CONSTANTS["BASE_API_URL"][CONSTANTS["ENVIRONMENT"]]}`);

// eslint-disable-next-line react/prop-types
export default function Screencast() {
    const { taskID, taskRunID } = useParams();

    const ref = useRef(null);
    const [image, setImage] = useState('');
    const [cursor, setCursor] = useState('');
    const [fullHeight, setFullHeight] = useState('');
    const [activeTab, setActiveTab] = useState(null);

    const [terminalLogs, setTerminalLogs] = useState([]);
    const [outputLog, setOutputLog] = useState(null);

    useEffect(() => {
        socket.emit('join-screencast', taskRunID);

        return () => {
        }
    }, [taskRunID]);

    useEffect(() => {
        socket.on('image', ({ img, fullHeight }) => {
            setImage('data:image/jpeg;base64,' + img);
            setFullHeight(fullHeight);
        });

        socket.on("cursor", (cur) => {
            setCursor(cur);
        });

        socket.on("activeTab", (activeTab) => {
            setActiveTab(activeTab);
        });

        socket.on("terminal-logs", (log) => {
            setTerminalLogs([...terminalLogs, log]);
        });

        socket.on("output-log", (log) => {
            setOutputLog(log);
            // set image to task completion image
            setImage(TaskCompletionImage);

        });
    });

    useEffect(() => {
        window.addEventListener("keydown", (event) => {
            const { key, code, location } = event;

            // Send the key information to the server
            console.log("keyPress", event);
            socket.emit("keyPress", { tabID: activeTab, key, code, location }, taskRunID);
            console.log("keypress done.");
        });

        return () => {
            window.removeEventListener("keydown", (event) => {
                const { key, code, location } = event;
                socket.emit("keyPress", { activeTab, key, code, location }, taskRunID);
            });
        };
    }, [activeTab, taskRunID]);


    const mouseMove = useCallback((event) => {
        const position = event.currentTarget.getBoundingClientRect();
        const widthChange = `${CONSTANTS["SCREENCAST_WIDTH"]}` / position.width;
        const heightChange = `${CONSTANTS["SCREENCAST_HEIGHT"]}` / position.height;

        console.log("mouseMove", event);
        socket.emit('mouseMove', {
            tabID: activeTab,
            taskRunID: taskRunID,
            x: widthChange * (event.pageX - position.left),
            y: heightChange * (event.pageY - position.top - document.documentElement.scrollTop),
        }, taskRunID);
        console.log("mousemove done.");
        console.log("Tab ID: ", activeTab);
    }, [activeTab, taskRunID]);

    const mouseClick = useCallback((event) => {
        const position = event.currentTarget.getBoundingClientRect();
        const widthChange = `${CONSTANTS["SCREENCAST_WIDTH"]}` / position.width;
        const heightChange = `${CONSTANTS["SCREENCAST_HEIGHT"]}` / position.height;
        socket.emit('mouseClick', {
            tabID: activeTab,
            x: widthChange * (event.pageX - position.left),
            y: heightChange * (event.pageY - position.top - document.documentElement.scrollTop),
        }, taskRunID);
    }, [activeTab, taskRunID]);

    const mouseScroll = useCallback((event) => {
        const position = event.currentTarget.scrollTop;
        console.log("mouseScroll", event);
        socket.emit('scroll', {
            tabID: activeTab,
            position
        }, taskRunID);
        console.log("mouseScroll done.");
    }, [activeTab, taskRunID]);

    // const splitLongString = (str, maxLength) => {
    //     if (str.length <= maxLength) {
    //         return [str];
    //     }

    //     const words = str.split(' ');
    //     let currentLine = '';
    //     let lines = [];

    //     for (let word of words) {
    //         if (currentLine.length + word.length + 1 > maxLength) {
    //             lines.push(currentLine);
    //             currentLine = '';
    //         }
    //         console.log("Current Line: ", currentLine);
    //         console.log("Lines: ", lines);
    //         currentLine += currentLine.length > 0 ? ' ' + word : word;
    //     }

    //     if (currentLine.length > 0) {
    //         lines.push(currentLine);
    //     }

    //     return lines;
    // };

    const splitLongString = (str, maxLength) => {
        if (str.length <= maxLength) {
            return [str];
        }

        let lines = [];
        const words = str.split('\n');

        for (let word of words) {
            for (let i = 0; i < word.length; i += maxLength) {
                lines.push(word.substring(i, i + maxLength));
            }
        }


        return lines;
    };



    const renderJsonValue = (value) => {
        if (typeof value === 'object' && value !== null) {
            if (Array.isArray(value)) {
                return (
                    <>
                        {value.map((item, index) => (
                            <React.Fragment key={index}>
                                {renderJsonValue(item)}
                                {<br />}
                            </React.Fragment>
                        ))}

                    </>
                );
            } else {
                return (
                    <>
                        {
                            Object.keys(value).map((key, index) => (
                                <React.Fragment key={index}>
                                    {index > 0 && ', '}
                                    <strong>{key}</strong>: {renderJsonValue(value[key])}
                                </React.Fragment>
                            ))
                        }
                    </>
                );
            }
        }

        if (typeof value === 'string') {
            const maxLength = 50;
            const lines = splitLongString(value, maxLength);
            return (
                <>
                    {lines.map((line, index) => (
                        <React.Fragment key={index}>
                            {<br />}
                            {line}
                        </React.Fragment>
                    ))}
                </>
            );
        }
        return <>{JSON.stringify(value)}</>;
    };



    return (
        <div className="screencast-task">
            <div className="left-container">
                <div className='task-info'>
                    <div className="task-id">
                        <strong style={{ fontWeight: "var(--font-weight-regular)" }}>Task ID:</strong> {taskID}
                    </div>
                    <div className="task-run-id">
                        <strong style={{ fontWeight: "var(--font-weight-regular)" }}>Task Run ID:</strong> {`${taskRunID.slice(0, 10)}...${taskRunID.slice(-10)}`}
                    </div>
                </div>
                {
                    image &&
                    <div className="popup" onScroll={mouseScroll}>
                        <div ref={ref} className="popup-ref" style={{ cursor, height: fullHeight }}>
                            <img alt="screencast" src={image} onMouseMove={mouseMove} onClick={mouseClick} />
                        </div>
                    </div>
                }
                <div className="sub-title"><strong>bvm.sh</strong> | Developed in <strong><span role="img" aria-label="India">🇮🇳</span></strong> with <span role="img" aria-label="Heart">❤️</span></div>

            </div>
            <div className="right-container">
                <div className='top-bar'>
                    <div className='tab-title'>
                        Terminal Logs
                    </div>
                    <div className='logs-container'>
                        {
                            terminalLogs.map((log, index) => {
                                return (
                                    <div className='log' key={index}>
                                        {log}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div className='bottom-bar'>
                    <div className='tab-title'>
                        Output
                    </div>
                    <div className='output-container'>
                        {outputLog &&
                            // <pre className='output-logs'>
                            //     {Object.keys(outputLog).map((key, index) => {
                            //         const value = outputLog[key];
                            //         return (
                            //             <span key={index}>
                            //                 <strong>{key}</strong>: {JSON.stringify(value)}
                            //             </span>
                            //         );
                            //     })}
                            // </pre>
                            (
                                <pre className='output-logs'>
                                    {Object.keys(outputLog).map((key, index) => (
                                        <span key={index}>
                                            <strong>{key}</strong>: {renderJsonValue(outputLog[key])}
                                        </span>
                                    ))}
                                </pre>
                            )
                        }
                    </div>
                </div>

            </div>
        </div >
    )
}

