import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const initialState = {
    userData: {}
}

const store = (set) => ({
    ...initialState,
    setUserData: (data) => set({ userData: data }),
    resetUserData: () => set({ userData: {} }),
});

export const useUserData = create(persist(store, { name: 'user-data' }))