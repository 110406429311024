import React, { useState, useEffect } from "react";
import { Pin } from "lucide-react";
import "./index.css";

const SidebarPanel = ({ onVisibilityChange, userName = "Guest" }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [isPinned, setIsPinned] = useState(false);
    const [isProfileOpen, setIsProfileOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const showSidebar = () => {
        if (!isPinned) {
            setIsVisible(true);
            onVisibilityChange(true);
        }
    };

    const hideSidebar = () => {
        if (!isPinned) {
            setIsVisible(false);
            onVisibilityChange(false);
        }
    };

    const toggleSidebar = () => {
        setIsVisible(!isVisible);
        onVisibilityChange(!isVisible);
    };

    const togglePin = () => {
        setIsPinned(!isPinned);
        onVisibilityChange(!isPinned);
    };

    const toggleProfile = () => setIsProfileOpen(!isProfileOpen);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <button
                className={`hamburger ${isVisible || isPinned ? "hidden" : ""}`}
                onClick={toggleSidebar}
            >
                <span className="hamburger-box">
                    <span className="hamburger-inner"></span>
                </span>
            </button>
            <div
                className={`sidebar ${isVisible || isPinned ? "visible" : ""} ${isPinned ? "pinned" : ""} ${isMobile ? "mobile" : ""}`}
                onMouseEnter={showSidebar}
                onMouseLeave={hideSidebar}
            >
                <div className="sidebar-content">
                    <div className="logo-container">
                        {/* <img src="/api/placeholder/150/50" alt="Website Logo" className="logo" /> */}
                        <img src='https://picsum.photos/150/50' alt="Website Logo" className="logo" />
                    </div>
                    <nav className="menu-section">
                        <ul>
                            <li><a href="#create-app">Create App</a></li>
                            <li><a href="#all-apps">All Apps</a></li>
                            <li><a href="#favorites">Favorites</a></li>
                            <li><a href="#your-apps">Your Apps</a></li>
                        </ul>
                    </nav>
                    <div className="profile-section">
                        <button className="profile-button" onClick={toggleProfile}>
                            {/* <img src="/api/placeholder/32/32" alt="User Avatar" /> */}
                            <img src='https://i.pravatar.cc/32' alt="User Avatar" />
                            <span className="user-name">{userName}</span>
                            <span className="chevron-down"></span>
                        </button>
                        {isProfileOpen && (
                            <div className="profile-dropdown">
                                <a href="#view-profile">View Profile</a>
                                <a href="#signout">Sign Out</a>
                            </div>
                        )}
                    </div>
                </div>
                <button className="pin-button" onClick={togglePin}>
                    <Pin size={24} className={`pin-icon ${isPinned ? "pinned" : ""}`} />
                </button>
            </div>
        </>
    );
};

export default SidebarPanel;