import GeometricShapesAnimation from "./../../../components/AnimatedShapes/index";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons'

const OpenSourceSection = () => (
    <section className="open-source-section">
        <GeometricShapesAnimation
            shapes={[
                { type: 'circle', color: 'var(--secondary-text-color)', top: '77.5%', left: '92.5%', size: '50px', duration: 5, delay: 0 },
                { type: 'circle', color: 'var(--neutral-100)', top: '77.5%', left: '86.5%', size: '50px', duration: 5, delay: 0 },
                { type: 'circle', color: 'var(--neutral-100)', top: '60%', left: '92.5%', size: '50px', duration: 5, delay: 0 },
                { type: 'circle', color: 'var(--neutral-200)', top: '42.5%', left: '92.5%', size: '50px', duration: 5, delay: 0 },
                { type: 'circle', color: 'var(--neutral-200)', top: '77.5%', left: '80.5%', size: '50px', duration: 5, delay: 0 },
                { type: 'circle', color: 'var(--neutral-200)', top: '60%', left: '86.5%', size: '50px', duration: 5, delay: 0 },
            ]}
            containerClassName="open-source-shapes"
        />
        <h2>Open Source & Education</h2>
        <h6>We support the open-source community and students.</h6>
        <p>Lifetime free access for:</p>
        <ul>
            <li><FontAwesomeIcon icon={faCheckSquare} size='md' /> Educational institutions</li>
            <li><FontAwesomeIcon icon={faCheckSquare} size='md' /> Non-profit organizations</li>
            <li><FontAwesomeIcon icon={faCheckSquare} size='md' /> Qualified students with valid ID</li>
        </ul>
        <button className="open-source-section__cta-button">Apply Now</button>
    </section>
);

export default OpenSourceSection;