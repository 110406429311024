import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faArrowRight } from '@fortawesome/free-solid-svg-icons'

const exchangeRates = {
    USD: 1,
    EUR: 0.85,
    GBP: 0.73,
    INR: 81,
    JPY: 110,
    NZD: 1.39,
    CAD: 1.25,
    AUD: 1.49,
    THB: 32.5
};

// SubscriptionPlans.jsx
const SubscriptionPlans = ({ billingCycle, setBillingCycle, currencyInfo }) => {
    const plans = [
        { name: 'Free', price: { monthly: 0, yearly: 0 }, features: ['Basic features', 'Limited usage'], cta: 'Start Free' },
        { name: 'Pro', price: { monthly: 20, yearly: 192 }, features: ['Advanced features', 'Priority support'], cta: 'Go Pro' },
        { name: 'Business', price: { monthly: 40, yearly: 384 }, features: ['All features', 'Dedicated support'], cta: 'Upgrade to Business' },
        { name: 'Custom', price: { monthly: 'Contact Us', yearly: 'Contact Us' }, features: ['Tailored solutions', 'Enterprise support'], cta: 'Contact Sales' },
    ];

    const convertPrice = (price) => {
        if (typeof price === 'number') {
            return Math.round(price * exchangeRates[currencyInfo.currency]);
        }
        return price;
    };

    return (
        <section className="subscription-plans">
            <div className="subscription-plans__billing-toggle">
                <button
                    className={billingCycle === 'monthly' ? 'subscription-plans__billing-toggle-button subscription-plans__billing-toggle-button--active' : 'subscription-plans__billing-toggle-button'}
                    onClick={() => setBillingCycle('monthly')}
                >
                    Monthly
                </button>
                <button
                    className={billingCycle === 'yearly' ? 'subscription-plans__billing-toggle-button subscription-plans__billing-toggle-button--active' : 'subscription-plans__billing-toggle-button'}
                    onClick={() => setBillingCycle('yearly')}
                >
                    Yearly (20% off)
                </button>
            </div>
            <div className="subscription-plans__plan-cards">
                {plans.map((plan) => (
                    <div key={plan.name} className="subscription-plans__plan-card">
                        <h3>{plan.name}</h3>
                        <p className="subscription-plans__plan-card-price">
                            {typeof plan.price[billingCycle] === 'number'
                                ? `${currencyInfo.symbol}${convertPrice(plan.price[billingCycle])}/${billingCycle === 'monthly' ? 'mo' : 'yr'}`
                                : plan.price[billingCycle]}
                        </p>
                        <button className="subscription-plans__plan-card-cta-button">{plan.cta} <FontAwesomeIcon icon={faArrowRight} /></button>
                        <hr className="subscription-plans__plan-card-divider" />
                        <ul>
                            {plan.features.map((feature, index) => (
                                <li key={index}><FontAwesomeIcon icon={faCheck} /> {feature}</li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default SubscriptionPlans;