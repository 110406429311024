import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './index.css';
import LogoAnimation from '../LogoAnimation';

const HeaderSection = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header className="header">
            <div className="header__container">
                <Link className="header__logo-link" to="/" style={{ textDecoration: "none" }}>
                    {/* <LogoAnimation /> */}
                    <div className="header__logo">
                        bvm<span className="header__logo--secondary">dotsh</span>
                    </div>
                </Link>
                <nav className={`header__nav ${isMenuOpen ? 'header__nav--open' : ''}`}>
                    <ul className="header__menu">
                        <li className="header__menu-item">
                            <Link className="header__menu-link" to="/">Home</Link>
                        </li>
                        <li className="header__menu-item">
                            <Link className="header__menu-link" to="/pricing">Pricing</Link>
                        </li>
                        <li className="header__menu-item">
                            <Link className="header__menu-link" to="/features">Docs</Link>
                        </li>
                        <li className="header__menu-item">
                            <Link className="header__menu-link" to="/features">Contact</Link>
                        </li>
                        <li className="header__menu-item header__menu-item--demo" onClick={() => window.location.href = "/"}>Book a Demo</li>
                        <li className="header__menu-item header__menu-item--login">
                            <Link className="header__menu-item--login-link" to="/login">Get Started Free</Link>
                        </li>
                    </ul>
                    <div className="header__hamburger-menu" onClick={toggleMenu}>
                        <div className={`header__hamburger ${isMenuOpen ? 'header__hamburger--open' : ''}`}>
                            <div className="header__hamburger-bar"></div>
                            <div className="header__hamburger-bar"></div>
                            <div className="header__hamburger-bar"></div>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    );
};

export default HeaderSection;
