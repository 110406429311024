import React from 'react';

const SecondBrainUI = () => {
    const features = [
        {
            title: 'Deterministic Results',
            description: 'Controlled workflows and precise outputs for critical tasks.',
        },
        {
            title: 'Language Agnostic',
            description: 'Overcome language barriers with our versatile solution.',
        },
        {
            title: 'Developer API',
            description: 'Build powerful applications on top of our automation platform.',
        },
        {
            title: 'Zero Hallucinations',
            description: 'Achieve 100% task reliability without AI-induced errors.',
        },
    ];

    return (
        <div className="app">
            <header className="header">
                <div className="header__logo">bvmdotsh</div>
                <nav className="header__nav">
                    <a href="#features" className="header__nav-link">Features</a>
                    <a href="#pillars" className="header__nav-link">Our Pillars</a>
                    <a href="#cta" className="header__nav-link">Get Started</a>
                </nav>
            </header>
            <section className="hero">
                <h1 className="hero__title">Automate Your Digital Tasks with Precision</h1>
                <p className="hero__subtitle">
                    bvmdotsh: Workflows, AI, and No-Code Solutions for 100% Task Reliability
                </p>
                <button className="hero__cta">Start Automating</button>
            </section>
            <section id="features" className="features">
                <h2 className="features__title">Key Features</h2>
                <div className="features__grid">
                    {features.map((feature, index) => (
                        <div key={index} className="features__item">
                            <h3 className="features__item-title">{feature.title}</h3>
                            <p className="features__item-description">{feature.description}</p>
                        </div>
                    ))}
                </div>
            </section>

            <section id="pillars" className="pillars">
                <h2 className="pillars__title">Our Two Pillars</h2>
                <div className="pillars__container">
                    <div className="pillars__item">
                        <h3 className="pillars__item-title">Time</h3>
                        <p className="pillars__item-description">
                            We give users back their time and energy by automating repetitive tasks.
                        </p>
                    </div>
                    <div className="pillars__item">
                        <h3 className="pillars__item-title">Personalization</h3>
                        <p className="pillars__item-description">
                            We create a deep sense of connection with users through personalized experiences.
                        </p>
                    </div>
                </div>
            </section>

            <section id="cta" className="cta">
                <h2 className="cta__title">Ready to Automate Your Tasks?</h2>
                <p className="cta__description">
                    Join bvmdotsh and experience the power of reliable, AI-driven automation.
                </p>
                <button className="cta__button">Get Started Now</button>
            </section>

            <footer className="footer">
                <div className="footer__content">
                    <p className="footer__copyright">&copy; 2024 bvmdotsh. All rights reserved.</p>
                    <div className="footer__links">
                        <a href="#" className="footer__link">Privacy Policy</a>
                        <a href="#" className="footer__link">Terms of Service</a>
                        <a href="#" className="footer__link">Contact Us</a>
                    </div>
                </div>
            </footer>

            <style>{`
        /* App.css */
.app {
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  color: #333;
}

/* Header.css */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header__logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}

.header__nav-link {
  margin-left: 1rem;
  text-decoration: none;
  color: #333;
  transition: color 0.3s ease;
}

.header__nav-link:hover {
  color: #0066cc;
}

/* Hero.css */
.hero {
  text-align: center;
  padding: 4rem 2rem;
  background-color: #f8f9fa;
}

.hero__title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.hero__subtitle {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #666;
}

.hero__cta {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: #0066cc;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.hero__cta:hover {
  background-color: #0052a3;
}

/* Features.css */
.features {
  padding: 4rem 2rem;
  background-color: #ffffff;
}

.features__title {
  text-align: center;
  margin-bottom: 2rem;
}

.features__grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.features__item {
  background-color: #f8f9fa;
  padding: 1.5rem;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.features__item-title {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.features__item-description {
  color: #666;
}

/* Pillars.css */
.pillars {
  padding: 4rem 2rem;
  background-color: #f8f9fa;
}

.pillars__title {
  text-align: center;
  margin-bottom: 2rem;
}

.pillars__container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.pillars__item {
  flex-basis: calc(50% - 2rem);
  background-color: #ffffff;
  padding: 1.5rem;
  margin: 1rem;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.pillars__item-title {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.pillars__item-description {
  color: #666;
}

/* CTA.css */
.cta {
  text-align: center;
  padding: 4rem 2rem;
  background-color: #0066cc;
  color: #ffffff;
}

.cta__title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.cta__description {
  margin-bottom: 2rem;
}

.cta__button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: #ffffff;
  color: #0066cc;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta__button:hover {
  background-color: #f0f0f0;
}

/* Footer.css */
.footer {
  background-color: #333;
  color: #ffffff;
  padding: 2rem;
}

.footer__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.footer__copyright {
  margin: 0;
}

.footer__links {
  display: flex;
}

.footer__link {
  color: #ffffff;
  text-decoration: none;
  margin-left: 1rem;
  transition: color 0.3s ease;
}

.footer__link:hover {
  color: #cccccc;
}
      `}</style>
        </div>
    );
};

export default SecondBrainUI;