import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './index.css';
import CONSTANTS from '../../config';

const AccountActivation = () => {
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const { userId, activationToken } = useParams();

    useEffect(() => {
        activateAccount(userId, activationToken);
    }, [userId, activationToken]);

    const activateAccount = async (userId, activationToken) => {
        await fetch(`${CONSTANTS["BASE_API_URL"][CONSTANTS["ENVIRONMENT"]]}/api/v1/user/activate/${userId}/${activationToken}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(({ success, data }) => {
                console.log('Success');
                setMessage(data.message);
            })
            .catch((error) => {
                console.error('There has been a problem with your fetch operation:', error);
                setError(error.message);
            });
    };

    return (
        <div className="activate-account-container">
            {error && <p>{error}</p>}
            {message &&
                <div className="activate-account-message">
                    {message}
                    <span className="close-tab-message">
                        You can now close this tab.
                    </span>
                </div>
            }
        </div>
    );
};

export default AccountActivation;
