const CONSTANTS = {
    "ENVIRONMENT": "production",
    "BASE_API_URL": {
        "development": "http://localhost:5000",
        "production": "https://api.bvm.sh:5000",
    },
    "BASE_URL": {
        "development": "http://localhost:3000",
        "production": "https://www.bvm.sh",
    },
    "SCREENCAST_HEIGHT": 720,
    "SCREENCAST_WIDTH": 1280
}

export default CONSTANTS
