import React, { useEffect, useRef, useState } from 'react';
import './index.css';

const LogoAnimation = () => {
    const flowerRef = useRef(null);
    const [isRotating, setIsRotating] = useState(true);

    useEffect(() => {
        // Stop rotation after 3 seconds
        const timer = setTimeout(() => {
            setIsRotating(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div
            className="flower-container"
            onMouseEnter={() => setIsRotating(true)}
            onMouseLeave={() => setIsRotating(false)}
        >
            <div
                ref={flowerRef}
                className={`flower ${isRotating ? 'rotating' : ''}`}
            />
        </div>
    );
};

export default LogoAnimation;