import { useRef, useState, useEffect, useCallback } from 'react'
import uuid from './functions/uuid'
import extractCodeFromBuffer from './functions/extractCodeFromBuffer'
import CONSTANTS from '../../config'

const conversationId = uuid()

export default function ChatInterface({ setCodeBlocks }) {
    const chatContainerRef = useRef(null)
    const [inputValue, setInputValue] = useState('')
    const [messages, setMessages] = useState([])
    const [selectedModel, setSelectedModel] = useState('')
    const [selectedPlatform, setSelectedPlatform] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight
        }
    }, [messages])

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!inputValue.trim()) return

        const newUserMessage = { type: 'user', content: inputValue }
        setMessages(prevMessages => [...prevMessages, newUserMessage])
        setInputValue('')
        setIsLoading(true)

        try {
            await fetchStream(inputValue, messages.length)
        } finally {
            setIsLoading(false)
        }
    }

    const fetchStream = useCallback(async (prompt, index) => {
        try {
            const response = await fetch(`${CONSTANTS["BASE_API_URL"][CONSTANTS["ENVIRONMENT"]]}/api/v1/task/getLLMResponse`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ prompt, conversationId, model: selectedModel, platform: selectedPlatform })
            })

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`)
            }

            const reader = response.body.getReader()
            const decoder = new TextDecoder()
            let _message = ''

            while (true) {
                const { done, value } = await reader.read()
                if (done) break
                const chunk = decoder.decode(value)
                _message += chunk
                const extractedCode = extractCodeFromBuffer(_message)
                setCodeBlocks(extractedCode)

                let messageWithoutCode = _message
                extractedCode.forEach(codeBlock => {
                    const codeRegex = new RegExp(`\`\`\`${codeBlock.language}?\\s*\\n([\\s\\S]*?)(\`\`\`|$)`, 'g')
                    messageWithoutCode = messageWithoutCode.replace(codeRegex, () => '[CODE BLOCK]')
                })

                setMessages(prevMessages => {
                    const newMessages = [...prevMessages]
                    newMessages[index + 1] = { type: 'api', content: messageWithoutCode }
                    return newMessages
                })
            }
        } catch (error) {
            setMessages(prevMessages => {
                const newMessages = [...prevMessages]
                newMessages[index + 1] = { type: 'error', content: `Error: ${error.message}` }
                return newMessages
            })
        }
    }, [setCodeBlocks, selectedModel, selectedPlatform])

    const renderMessage = (message) => {
        return message.content.split('\n').map((line, i) => (
            <p key={i} className="message-line">{line}</p>
        ));
    };

    return (
        <>
            <div className="select-container">
                <div className="selector">
                    <select
                        value={selectedPlatform}
                        onChange={(e) => setSelectedPlatform(e.target.value)}
                        className="select"
                    >
                        <option value="">Select Platform</option>
                        <option value="platform1">Platform 1</option>
                        <option value="platform2">Platform 2</option>
                        <option value="platform3">Platform 3</option>
                    </select>

                    <select
                        value={selectedModel}
                        onChange={(e) => setSelectedModel(e.target.value)}
                        className="select"
                    >
                        <option value="">Select Model</option>
                        <option value="model1">Model 1</option>
                        <option value="model2">Model 2</option>
                        <option value="model3">Model 3</option>
                    </select>
                </div>
                <div className="powered">Powered by <strong>bvmdotsh</strong></div>
            </div>
            <div className="chat-container" ref={chatContainerRef}>
                {messages.map((message, index) => (
                    <div key={index} className={`message ${message.type}`}>
                        {renderMessage(message)}
                    </div>
                ))}
            </div>
            <form onSubmit={handleSubmit} className="input-form">
                <input
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    placeholder="Type your message here..."
                    className="input-field"
                    disabled={isLoading}
                />
                <button type="submit" className="send-button" disabled={isLoading}>
                    {isLoading ? 'Sending...' : 'Send'}
                </button>
            </form>
        </>
    )
}