import React from 'react';
import './index.css';

const ApiPlaygroundSection = () => {
    return (
        <div className="api-section">
            <div className="api-section__playground">
                <h2 className="api-section__heading">API Playground: Unleash Your Creativity</h2>
                <h3 className="api-section__subheading">Explore, integrate, innovate.</h3>
                <p className="api-section__description">
                    Access our APIs and open the door to streamlined processes. Whether you’re building apps, automating workflows, or enhancing user experiences, our APIs empower you to create magic.
                </p>
                <div className="api-section__cta">
                    <input type="email" className="api-section__email-input" placeholder="* Email address" />
                    <a href="https://chromewebstore.google.com/detail/bvmdotsh/ahbgmefbaaihbdbbjbiahjfgcabmapmo">
                        <button className="api-section__cta-button">Secure early access</button>
                    </a>
                </div>
                <p className="api-section__tagline">Our APIs are your canvas; let creativity flow.</p>
            </div>
        </div>
    );
}

export default ApiPlaygroundSection;
