import React, { useState } from 'react';
import './index.css';

const FaqSection = ({ faqs }) => {
    const [isOpen, setIsOpen] = useState({}); // Assuming you have 2 FAQ items initially

    const toggleFAQ = (index) => {
        setIsOpen((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    return (
        <div className="faq-section">
            <div className="faq-section__container">
                <div className="faq-section__header">
                    <div className="faq-section__title">
                        <h2 className="faq-section__heading">FAQ</h2>
                        <p className="faq-section__description">
                            If you have any other questions, please get in touch with us at
                            <strong style={{ fontWeight: "600" }}>
                                <a href="mailto:support@bvm.sh" className="faq-section__contact-link"> support@bvm.sh</a>
                            </strong>
                        </p>
                    </div>
                </div>
                <div className="faq-section__list">
                    {faqs.map((item, index) => (
                        <FaqItem
                            key={index}
                            isOpen={isOpen[index]}
                            toggleOpen={() => toggleFAQ(index)}
                            question={item.question}
                            answer={item.answer}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

const FaqItem = ({ isOpen, toggleOpen, question, answer }) => {
    return (
        <div className={`faq-section__item ${isOpen ? 'faq-section__item--open' : ''}`} onClick={toggleOpen}>
            <div className="faq-section__question">
                <h3 className="faq-section__question-heading">{question}</h3>
                <span className="faq-section__question-toggle">{isOpen ? '-' : '+'}</span>
            </div>
            <div className="faq-section__answer">{answer}</div>
        </div>
    );
};

export default FaqSection;



