import React from 'react';

const PrivacyPolicySection = ({ title, children }) => {
    return (
        <div className="privacy-policy__section">
            <h3 className="privacy-policy__subheading">{title}</h3>
            {children}
        </div>
    );
};

export default PrivacyPolicySection;
