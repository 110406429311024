import { useState, useEffect } from 'react'
import CodeRenderer from './CodeRenderer'
import './artifacts.css'
import AceEditor from 'react-ace'

import 'ace-builds/src-noconflict/ace';
import 'ace-builds/src-noconflict/ext-beautify';
import "ace-builds/src-noconflict/theme-solarized_dark";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/theme-xcode";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/theme-textmate";
import "ace-builds/src-noconflict/theme-kuroir";
import "ace-builds/src-noconflict/theme-twilight";
import "ace-builds/src-noconflict/theme-solarized_light";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/mode-jsx";
import 'ace-builds/src-noconflict/snippets/javascript';
import 'ace-builds/src-noconflict/snippets/json';
import 'ace-builds/src-noconflict/snippets/html';
import 'ace-builds/src-noconflict/snippets/css';
import 'ace-builds/src-noconflict/snippets/jsx';

export default function CodeBlocks({ codeBlocks, onChange }) {
    const [activeButton, setActiveButton] = useState('')

    useEffect(() => {
        if (activeButton) return
        if (!codeBlocks.length) return
        setActiveButton(codeBlocks[0].language)
    }, [codeBlocks, activeButton])

    const handleCodeChange = (index, newValue) => {
        const newCodeBlocks = [...codeBlocks];
        newCodeBlocks[index].code = newValue;
        onChange(newCodeBlocks);
    };

    return (
        <div className={`codeBlocksWrapper ${codeBlocks.length ? 'show' : ''}`}>
            <div className={`tabHeader`}>
                <button
                    onClick={() => setActiveButton('preview')}
                    className={`tabItem ${activeButton === 'preview' ? 'active' : ''}`}
                >
                    Preview
                </button>

                {codeBlocks.map((block, index) => (
                    <button
                        key={index}
                        onClick={() => setActiveButton(block.language)}
                        className={`tabItem ${activeButton === block.language ? 'active' : ''}`}
                    >
                        {block.language}
                    </button>
                ))}
            </div>

            <div className={"tabContent"}>
                <div className={`tabItem preview ${activeButton === 'preview' ? 'active' : ''}`}>
                    <CodeRenderer codeBlocks={codeBlocks} />
                </div>

                {codeBlocks.map((block, index) => (
                    <div key={index} className={`tabItem ${activeButton === block.language ? 'active' : ''}`}>
                        <AceEditor
                            placeholder="Placeholder Text"
                            mode={block.language}
                            name={block.language}
                            theme="tomorrow"
                            value={block.code}
                            readOnly={false}
                            fontSize={16}
                            width="100%"
                            height="90vh"
                            lineHeight={24}
                            showPrintMargin={true}
                            showGutter={false}
                            highlightActiveLine={true}
                            onChange={(newValue) => handleCodeChange(index, newValue)}
                            setOptions={{
                                enableBasicAutocompletion: true,
                                enableLiveAutocompletion: true,
                                enableSnippets: true,
                                showLineNumbers: false,
                                tabSize: 2,
                                useWorker: false
                            }}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}