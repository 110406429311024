import React, { useState, useEffect } from 'react';
import './index.css';
import HeaderSection from './../../components/Header';
import FooterSection from './../../components/Footer';
import FaqSection from './../../components/FAQ';

import PricingHeader from './components/PricingHeader';
import SubscriptionPlans from './components/SubscriptionPlans';
import PayAsYouGo from './components/PayAsYouGo';
import PlanFeatures from './components/PlanFeatures';
import OpenSourceSection from './components/OpenSource';

const getCurrencySymbol = (countryCode) => {
    const currencyMap = {
        US: { currency: 'USD', symbol: '$' },
        GB: { currency: 'GBP', symbol: '£' },
        IN: { currency: 'INR', symbol: '₹' },
        JP: { currency: 'JPY', symbol: '¥' },
        DE: { currency: 'EUR', symbol: '€' },
        FR: { currency: 'EUR', symbol: '€' },
        IT: { currency: 'EUR', symbol: '€' },
        CA: { currency: 'CAD', symbol: 'C$' },
        TH: { currency: 'THB', symbol: '฿' },
        AU: { currency: 'AUD', symbol: 'A$' },
        NZ: { currency: 'NZD', symbol: 'NZ$' },
    };
    return currencyMap[countryCode] || currencyMap.US;
};

const PricingPage = () => {
    const [billingCycle, setBillingCycle] = useState('monthly');
    const [userCountry, setUserCountry] = useState('US');
    const [currencyInfo, setCurrencyInfo] = useState({ currency: 'USD', symbol: '$' });

    useEffect(() => {
        fetch('https://www.cloudflare.com/cdn-cgi/trace')
            .then(res => res.text()).then(t => {
                var data = t.replace(/[\r\n]+/g, '","').replace(/\=+/g, '":"');
                data = '{"' + data.slice(0, data.lastIndexOf('","')) + '"}';
                var jsondata = JSON.parse(data);
                console.log(jsondata.loc);
                const userLocation = jsondata.loc;
                setUserCountry(userLocation);
                setCurrencyInfo(getCurrencySymbol(userLocation));
            });
    }, []);

    const faqs = [
        {
            question: 'What payment methods do you accept?',
            answer: 'We accept major credit cards (Visa, MasterCard, American Express, RuPay), UPI, PayPal and all other major payment methods.',
        },
        {
            question: 'Do you offer any discounts for non-profits or educational institutions?',
            answer: 'Yes, we offer lifetime free accounts for qualified non-profits, educational institutions and students. Please contact us for more information.',
        },
        {
            question: 'Is there a free trial available?',
            answer: 'Yes, we offer lifetime free version of our product.',
        },
        {
            question: 'Can I upgrade or downgrade my plan at any time?',
            answer: 'Yes, you can upgrade or downgrade your plan at any time. Changes will take effect at the start of your next billing cycle.',
        },
        {
            "question": "Do you offer custom plans for enterprises?",
            "answer": "Yes, we offer custom plans tailored to the needs of enterprises. Please contact us for more information."
        }
    ];

    return (
        <div className="pricing-page">
            <HeaderSection />
            <div className="pricing-section">
                <div className="pricing-section__subscription">
                    <PricingHeader />
                    <SubscriptionPlans
                        billingCycle={billingCycle}
                        setBillingCycle={setBillingCycle}
                        currencyInfo={currencyInfo}
                    />
                </div>
                <div className="pricing-section__pay-as-you-go">
                    <PayAsYouGo currencyInfo={currencyInfo} />
                </div>
                <div className="pricing-section__open-source">
                    <OpenSourceSection />
                </div>
                <PlanFeatures />
                <FaqSection faqs={faqs} />
            </div>
            <FooterSection />
        </div>
    );
};

export default PricingPage;