import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

const PlanFeatures = () => {
    const features = [
        { name: 'Feature 1', free: true, pro: true, business: true, custom: true },
        { name: 'Feature 2', free: false, pro: true, business: true, custom: true },
        { name: 'Feature 3', free: false, pro: false, business: true, custom: true },
        { name: 'Feature 4', free: false, pro: false, business: false, custom: true },
    ];

    return (
        <section className="plan-features">
            <h2>Detailed Plan Features</h2>
            <table>
                <thead>
                    <tr>
                        <th>Feature</th>
                        <th>Free</th>
                        <th>Pro</th>
                        <th>Business</th>
                        <th>Custom</th>
                    </tr>
                </thead>
                <tbody>
                    {features.map((feature, index) => (
                        <tr key={index}>
                            <td>{feature.name}</td>
                            <td>{feature.free ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />}</td>
                            <td>{feature.pro ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />}</td>
                            <td>{feature.business ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />}</td>
                            <td>{feature.custom ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </section>
    );
};

export default PlanFeatures;