import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import AccountActivation from './pages/AccountActivation';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import Screencast from './pages/Screencast';
import Artifacts from './pages/Artifacts/Artifacts';
import PricingPage from './pages/Pricing';
import SecondBrainUI from './pages/Test';
import LoginSignup from './pages/Account';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />, // Replace with your default layout component or any other component you want to render at the root path
  },
  {
    path: '/activate/:userId/:activationToken',
    element: <AccountActivation />,
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
  },
  {
    path: '/terms-of-service',
    element: <TermsOfService />
  },
  {
    path: '/runTask/:taskID/:taskRunID',
    element: <Screencast />
  },
  {
    path: '/artifacts',
    element: <Artifacts />
  },
  {
    path: '/pricing',
    element: <PricingPage />
  },
  {
    path: '/test',
    element: <SecondBrainUI />
  },
  {
    path: '/login',
    element: <LoginSignup />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);