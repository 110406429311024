import React from 'react';
import './index.css';
import Video from '../../assets/video/bvmdotsh-demo.mp4';

const HeroSection = () => {
    return (
        <div className="hero-section">
            <div className="hero-section__left">
                <h1 className="hero-section__heading">Automate the mundane, <br />focus on what matters.</h1>
                <p className="hero-section__subheading">Unlock exponential productivity, <br /> streamline growth, reclaim time.</p>
                <p className="hero-section__subheading hero-section__subheading--secondary">From food delivery to flight bookings, <br /> we've got you covered.</p>
                <div className="hero-section__cta">
                    <a href="https://chromewebstore.google.com/detail/bvmdotsh/ahbgmefbaaihbdbbjbiahjfgcabmapmo">
                        <button className="hero-section__cta-button hero-section__cta-button--primary"><span className="hero-section__cta-button-icon material-symbols-outlined">download</span>Download Free Extension</button>
                    </a>
                    <button className="hero-section__cta-button hero-section__cta-button--secondary">Start Free with Email</button>
                </div>
            </div>
            <div className="hero-section__right">
                <div className="hero-section__video">
                    <video autoPlay muted loop>
                        <source src={Video} type="video/mp4" />
                    </video>
                </div>
            </div>
        </div>
    );
};

export default HeroSection;
